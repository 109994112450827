@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    font-family: 'Montserrat', sans-serif;
  }
  

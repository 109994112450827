.ngx-datatable.bootstrap:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection)
  .datatable-body-row:hover
  .datatable-row-group {
  cursor: pointer;
}

.datatable-body-cell-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.container-xxl {
  max-width: 2560px !important;
}

.page-count > .ng-star-inserted {
  display: none !important;
}
